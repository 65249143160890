import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Photo from '../../assets/pot-n-plot.png';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 75%;
    margin: auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
        margin: auto;
        width: 100%;
    }

    .buttons-container {
        @media screen and (max-width: 759px) {
            display: grid;
        }
    }
`

const Nav = styled.ul`
    list-style-type: none;
    position: fixed;
    width: 100vh;
    bottom: 0;
    right: 6.5em;
    line-height: 2em;
    transform: rotate(90deg);
    transform-origin: 100% 100%;
    .link {
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.1em;
    }
    .link:hover {
        border-bottom: 1px solid #9d7ea1;
        width: 80%;
        
    }
`

const Picture = styled.div`
    .profile {
        width: 100%;
        border: 1px solid black;
    }
`

const Header = styled.p`
    font-size: 40px;
    // text-transform: uppercase;

    @media screen and (max-width: 759px) {
        margin: auto;
        text-align: center;
        padding-bottom: 30px;
    }
`

const Description = styled.div`
    text-align: center;
    width: 70%;

    @media screen and (max-width: 759px) {
        margin: auto;
    }
`

const Button = styled.button`
    border: 1px solid #9d7ea1;
    background-color: white;
    padding: 8px 35px;
    color: #9d7ea1;
    letter-spacing: 0.1em;
    font-size: 11px;
    text-transform: uppercase;
    :hover {
        border: 1px solid #9d7ea1;
        background-color: #9d7ea1;
        padding: 8px 35px;
        color: black;
        letter-spacing: 0.1em;
        font-size: 11px;
        text-transform: uppercase;
        color: white;
    }
`

const Title = styled.p`
    text-transform: uppercase;
`

const Skills = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    @media screen and (max-width: 759px) {
        grid-template-columns: auto auto;
    }
`

const Spacer = styled.span`
    padding: 20px;

    @media screen and (max-width: 759px) {
        padding: 5px;
    }
`

function Pot() {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <Container>
           <Description>
               <p>PROJECTS</p>
               <div className="buttons-container">
                    <Header>Pot n' Plot: A Virtual Gardening Website</Header>
                    <a href="http://pot-n-plot.herokuapp.com"><Button>Live Demo</Button></a>
                    <Spacer />
                    <a href="https://github.com/mwong068/pot-n-plot-app"><Button>Back End</Button></a>
               </div>
                <div>
                    <Spacer />
                    <p>A virtual gardening site made entirely using Rails as a database and front end display. It is a full CRUD application built using the MVC architecture, Bootstrap for styling, and seeded with data from the Trefle API.</p>
                </div>
                <div>
                   <Spacer />
                    <Title>Built using</Title>
                    <Skills>
                        <p>Ruby on Rails</p>
                        <p>PostgreSQL</p>
                        <p>bcrypt</p>
                        <p>Trefle API</p>
                        <p>Bootstrap</p>
                    </Skills>
                </div>
           </Description>
            <Picture>
                <br></br>
                <img src={Photo} className="profile" />
            </Picture>
        </Container>
    )
}

export default Pot;