import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTshirt, faBreadSlice, faSchool, faCookie } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Container = styled.div`
    width: 60%;
    margin: auto;
    @media screen and (max-width: 759px) {
        width: 100%;
        margin: auto;
    }
`

const Header = styled.p`
    font-size: 30px;
    text-align: left;
    text-transform: uppercase;

    @media screen and (max-width: 759px) {
        text-align: center;
    }
`
    
const Introduction = styled.div`
    padding: 15px;
    width: 90%;
    margin: auto;
`

const Timeline = styled.div`
    background-color: #e1d7e2;
    border-radius: 5px;
    width: 55%;
    margin: auto;
    padding: 0px 30px;

    @media screen and (max-width: 759px) {
        width: 90%;
        padding: 5px;
    }

    .vertical-timeline-element--work {
        @media screen and (max-width: 759px) {
            width: 100%;
        }
    }
`


function Experience() {
    return (
        <Container>
            <Header>work experience</Header>
                <Introduction>
                    <p>Experience in Ruby on Rails and Javascript based programming and recently a Software Engineer Intern with a small startup in San Francisco. Former pastry chef who developed hundreds of products from scratch daily and employed adaptability in a fast-paced environment. 2+ years in project management and a knack for conceptualizing and delivering elegant, user-friendly solutions. Excited to connect people through technology after many years of connecting people through food.</p>
                </Introduction>
                <Introduction></Introduction>
                <Timeline>
                    <VerticalTimeline layout={"1-column-left"} style={{color: 'black'}} lineStyle={{color: '#9d7ea1'}}>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="November 2021 - Present"
                            iconStyle={{ background: '#9d7ea1', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faCookie} style={{marginLeft: '-8px'}} />}
                        >
                            <h3 className="vertical-timeline-element-title">Full Stack Web Developer</h3>
                            <h4 className="vertical-timeline-element-subtitle">Coat Rack Web Services, LLC</h4>
                            <ul>
                            <li>Plan, design, implement, and maintain new and existing features for non-profit companies with platforms built using React.js, Java, Ruby on Rails, PHP/Wordpress</li>
                            <li>Extensive unit testing with custom build in endpoints in Java backend to ensure that all parts of product are functional and work efficiently</li>
                            <li>Created multiple front end features to increase customer retention and satisfaction by 5% overall including counselor availability functionality for more support for customers</li>
                            </ul>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9d7ea1', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #9d7ea1' }}
                            date="May - September 2020"
                            iconStyle={{ background: '#9d7ea1', color: '#fff' }}
                            
                            icon={<FontAwesomeIcon icon={faTshirt} style={{marginLeft: '-10px'}} />}
                        >
                            <h3 className="vertical-timeline-element-title">Software Engineer Intern</h3>
                            <h4 className="vertical-timeline-element-subtitle">Dopple</h4>
                            <ul>
                            <li>Plan, design, and implement new and existing features for e-commerce platform using React.js and Django</li>
                            <li>Developed address verification component for customers integrating USPS API to increase user retention and reduce subscription loss</li>
                            <li>Collaborate with the design and product teams to deliver top of the line visual experiences and engage in Agile Software Development team</li>
                            </ul>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="May - September 2020"
                            iconStyle={{ background: '#9d7ea1', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faSchool} style={{marginLeft: '-10px'}} />}
                        >
                            <h3 className="vertical-timeline-element-title">Code Coach</h3>
                            <h4 className="vertical-timeline-element-subtitle">theCoderSchool</h4>
                            <ul>
                            <li>Tutor and teach students programming languages such as Python and Scratch using Zoom to hold online classes and meetings</li>
                            <li>Encourage students to learn and understand different concepts together by applying what they learned to exercises and homework</li>
                            </ul>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                </Timeline>
        </Container>
    )
}

export default Experience;