import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Preview from '../../assets/sweet-spot-preview.png';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 75%;
    margin: auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
        margin: auto;
        width: 100%;
    }

    .buttons-container {
        @media screen and (max-width: 759px) {
            display: grid;
        }
    }
`

const Nav = styled.ul`
    list-style-type: none;
    position: fixed;
    width: 100vh;
    bottom: 0;
    right: 6.5em;
    line-height: 2em;
    transform: rotate(90deg);
    transform-origin: 100% 100%;
    .link {
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.1em;
    }
    .link:hover {
        border-bottom: 1px solid #9d7ea1;
        width: 80%;
        
    }
`

const Picture = styled.div`
    .profile {
        width: 100%;
        border: 1px solid black;
    }
`

const Header = styled.p`
    font-size: 40px;
    // text-transform: uppercase;

    @media screen and (max-width: 759px) {
        margin: auto;
        text-align: center;
        padding-bottom: 30px;
    }
`

const Description = styled.div`
    text-align: center;
    width: 70%;

    @media screen and (max-width: 759px) {
        margin: auto;
    }
`

const Button = styled.button`
    border: 1px solid #9d7ea1;
    background-color: white;
    padding: 8px 35px;
    color: #9d7ea1;
    letter-spacing: 0.1em;
    font-size: 11px;
    text-transform: uppercase;
    :hover {
        border: 1px solid #9d7ea1;
        background-color: #9d7ea1;
        padding: 8px 35px;
        color: black;
        letter-spacing: 0.1em;
        font-size: 11px;
        text-transform: uppercase;
        color: white;
    }
`

const Spacer = styled.span`
    padding: 10px;

    @media screen and (max-width: 759px) {
        padding: 5px;
    }
`

const Title = styled.p`
    text-transform: uppercase;
`

const Skills = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    @media screen and (max-width: 759px) {
        grid-template-columns: auto auto;
    }
`


function COVID() {

    return (
        <Container>
           <Description>
               <div>
                    <Title>projects</Title>
                    <Header>Sweet Spot: A Pastry E-Commerce Site</Header>
               </div>
                <div className="buttons-container">
                    <a href="http://sweet-spot.netlify.app/"><Button>Live Demo</Button></a>
                    <Spacer />
                    <a href="https://github.com/mwong068/sweet-spot-front-end"><Button>Front End</Button></a>
                    <Spacer />
                    <a href="https://github.com/mwong068/sweet-spot-back-end"><Button>Back End</Button></a>
                </div>
                <div>
                    <Spacer />
                    <p>An eclectic e-commerce website for pastry chefs and small businesses to be able to sell their baked goods to people in the community or even the world.</p>
                </div>
                <div>
                    <Spacer />
                    <Title>Built using</Title>
                    <Skills>
                        <p>Ruby on Rails</p>
                        <p>React</p>
                        <p>Redux</p>
                        <p>PostgreSQL</p>
                        <p>Thunk</p>
                        <p>Stripe API</p>
                        <p>JSON Web Tokens</p>
                    </Skills>
                </div>
           </Description>
            <Picture>
                <br></br>
                <img src={Preview} className="profile" />
            </Picture>
        </Container>
    )
}

export default COVID;