import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import Homepage from './Homepage';
import Projects from './Projects/Projects.js';
import About from './About/About.js';
import Skills from './About/Skills.js';
import COVID from './Projects/COVID.js';
import Hikr from './Projects/Hikr.js';
import Sweet from './Projects/SweetSpot.js';
import Pick from './Projects/Pick.js';
import Pot from './Projects/Pot.js';
import Experience from './Experience.js';
import Contact from './Contact.js';

const Container = styled.div`
    padding: 50px 50px;
`

function Main() {
    return (
        <Container>
            <Route exact path='/' component={Homepage} />
            <Route exact path='/projects' component={Projects} />
            <Route path='/about' component={About} />
            <Route path='/skills' component={Skills} />
            <Route exact path='/projects/COVID' component={COVID} />
            <Route exact path='/projects/SweetSpot' component={Sweet} />
            <Route exact path='/projects/Hikr' component={Hikr} />
            <Route exact path='/projects/PickandChews' component={Pick} />
            <Route exact path='/projects/PotnPlot' component={Pot} />
            <Route exact path='/resume' component={Experience} />
            <Route exact path='/contact' component={Contact} />
        </Container>
    )
}

export default Main;