import React, { useState, useEffect } from 'react';
import './App.css';
import BurgerMenu from './components/BurgerMenu';
import Main from './components/Main';
import SideMenu from './components/SideMenu';
import Navigation from './components/Navigation';

export default function App() {

  const [showNav, setShowNav] = useState(false);

  let sideMenu;

  const toggleNav = () => {
      setShowNav(!showNav);
  }

  const backdropClickHandler = () => {
    setShowNav(false);
  }

  useEffect(() => {
    if (showNav === true) {
      sideMenu = <SideMenu click={backdropClickHandler} />
    }
  })

  return (
    <div className="App">
        {/* <Navigation /> */}
        <SideMenu click={backdropClickHandler} showNav={showNav} />
        <BurgerMenu toggleNav={toggleNav} />
      <Main />
    </div>
  );
}
