import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import COVID from '../../assets/covid-19-image.png';
import Sweet from '../../assets/sweet-spot-preview.png';
import Hikr from '../../assets/hikr.png';
import Pick from '../../assets/pickandchews.png';
import Pot from '../../assets/pot-n-plot.png';

const Container = styled.div`
    width: 80%;
    margin: auto;
`
const Header = styled.p`
    font-size: 30px;
    text-align: left;
    text-transform: uppercase;

    @media screen and (max-width: 759px) {
        text-align: center;
    }
`

const Holder = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
    }
`

const Project = styled.div`
    padding: 20px;
    max-height: 210px;
    .project {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .project:hover {
        // border: 5px solid orange;
        // -webkit-transform: scale(1.2);
        // -ms-transform: scale(1.2);
        // transform: scale(1.2);
        transition: .5s ease;
        background-color: #9d7ea1;
        opacity: 0;
    }
`

const Overlay = styled.div`
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: #9d7ea1;
`

const Text = styled.p`
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 1;
`



function Projects() {
    return (
        <Container>
            <Header>Projects</Header>
            <Holder>
                <Project>
                    <Overlay>
                        <Link to="/projects/COVID"><img src={COVID} className="project" /></Link>
                        <Text className="text">COVID-19 Inventory Checklist</Text>
                    </Overlay>
                </Project>
                <Project>
                    <Overlay>
                        <Link to="/projects/SweetSpot"><img src={Sweet} className="project" /></Link>
                        <Text className="text">Sweet Spot</Text>
                    </Overlay>
                </Project>
                <Project>
                    <Overlay>
                        <Link to="/projects/Hikr"><img src={Hikr} className="project" /></Link>
                        <Text className="text">Hikr</Text>
                    </Overlay>
                </Project>
                <Project>
                    <Overlay>
                        <Link to="/projects/PickandChews"><img src={Pick} className="project" /></Link>
                        <Text className="text">Pick and Chews</Text>
                    </Overlay>
                </Project>
                <Project>
                    <Overlay>
                        <Link to="/projects/PotnPlot"><img src={Pot} className="project" /></Link>
                        <Text className="text">Pot n' Plot</Text>
                    </Overlay>
                </Project>
            </Holder>
        </Container>
    )
}

export default Projects;