import styled from 'styled-components';
import picture from '../../assets/profile_pic.jpg';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin: auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
    }
`

const Nav = styled.ul`
    list-style-type: none;
    position: fixed;
    width: 100vh;
    bottom: 0;
    right: 6.5em;
    line-height: 2em;
    transform: rotate(90deg);
    transform-origin: 100% 100%;
    .link {
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.1em;
    }
    .link:hover {
        border-bottom: 1px solid #9d7ea1;
        width: 80%;
        
    }

    @media screen and (max-width: 759px) {
        right: 2em;
    }
`

const Link = styled.li`
    display: inline-block;
`

const Picture = styled.div`
    .profile {
        width: 60%;
    }

    @media screen and (max-width: 759px) {
        padding-bottom: 5px;
    }
`

const Description = styled.div`
    text-align: left;
    width: 80%;

    @media screen and (max-width: 759px) {
        padding-top: 5px;
        text-align: center;
        margin: auto;
    }
`

function About() {

    return (
        <Container>
            <Nav>
                <NavLink to="/about" className="link"><Link>My Story</Link></NavLink>
                <Link style={{'color': 'white' }}>♡♡♡</Link>
                <NavLink to="/skills" className="link"><Link>Technical Skills</Link></NavLink>
            </Nav>
            <Picture>
                <br></br>
                <img src={picture} className="profile" />
            </Picture>
           <Description>
                <p>My enthusiasm for coding began at a very young age, building pet pages on Neopets. I fell in love instantly and spent a year or two playing around with HTML and CSS.</p>
                <p>I've always been interested in programming, but decided to pursue it full time as a career by attending Flatiron School in San Francisco. There I specialized in Rails, Javascript, and React.</p>
                <p>Now I'm very excited to have found a profession in which I can combine my passion for creating things from scratch with my excitement for technology.</p>
           </Description>
        </Container>
    )
}

export default About;