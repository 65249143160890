import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gif from '../../assets/covid-19.gif';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 75%;
    margin: auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
        margin: auto;
        width: 100%;
    }

    .buttons-container {
        @media screen and (max-width: 759px) {
            display: grid;
        }
    }
`

const Picture = styled.div`
    .profile {
        width: 100%;
        border: 1px solid black;
    }
`

const Header = styled.p`
    font-size: 40px;
    // text-transform: uppercase;

    @media screen and (max-width: 759px) {
        margin: auto;
        text-align: center;
        padding-bottom: 30px;
    }
`

const Description = styled.div`
    text-align: center;
    width: 70%;
    display: grid;

    @media screen and (max-width: 759px) {
        margin: auto;
    }
`
const Button = styled.button`
    border: 1px solid #9d7ea1;
    // background-color: #9d7ea1;
    background-color: white;
    // border-radius: 5px;
    padding: 8px 35px;
    color: #9d7ea1;
    letter-spacing: 0.1em;
    font-size: 11px;
    // box-shadow: 5px 7px 10px #c4a4bb;
    text-transform: uppercase;
    :hover {
        border: 1px solid #9d7ea1;
        background-color: #9d7ea1;
        padding: 8px 35px;
        color: black;
        letter-spacing: 0.1em;
        font-size: 11px;
        text-transform: uppercase;
        color: white;
    }
`

const Spacer = styled.span`
    padding: 20px;

    @media screen and (max-width: 759px) {
        padding: 5px;
    }
`

const Title = styled.p`
    text-transform: uppercase;
`

const Skills = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    @media screen and (max-width: 759px) {
        grid-template-columns: auto auto;
    }
`

function COVID() {

    return (
        <Container>
           <Description>
               <div>
                    <Title>projects</Title>
                    <Header>COVID-19 Inventory Checklist</Header>
               </div>
                <div className="buttons-container">
                    <a href="https://github.com/mwong068/covid-19-inventory-checklist-frontend" ><Button>Front End</Button></a>
                    <Spacer />
                    <a href="https://github.com/mwong068/covid-19-inventory-checklist-backend-"><Button>Back End</Button></a>
                </div>
                <div>
                    <Spacer />
                    <p>An app created to help people all over the world maintain an inventory of their home items in a time when resources are more difficult to obtain. The app was designed to bring awareness to individuals with excessive amounts of items in hopes that they may donate their unused products to those in need. This app was built for the Twilio and Dev.to hackathon.</p>
                </div>
                <div>
                    <Title>Built using</Title>
                    <Skills>
                        <p>Ruby on Rails</p>
                        <p>React</p>
                        <p>Redux</p>
                        <p>PostgreSQL</p>
                        <p>Twilio SMS</p>
                    </Skills>
                </div>
           </Description>
            <Picture>
                <br></br>
                <img src={gif} className="profile" />
            </Picture>
        </Container>
    )
}

export default COVID;