import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faHtml5, faJsSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
    width: 80%;
    margin: auto;
`

const Nav = styled.ul`
    list-style-type: none;
    position: fixed;
    width: 100vh;
    bottom: 0;
    right: 6.5em;
    line-height: 2em;
    transform: rotate(90deg);
    transform-origin: 100% 100%;
    .link {
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.1em;
    }
    .link:hover {
        border-bottom: 1px solid #9d7ea1;
        width: 80%;
        
    }

    @media screen and (max-width: 759px) {
        right: 2em;
    }
`

const Link = styled.li`
    display: inline-block;
`

const Description = styled.div`
    text-align: left;
    width: 100%;
`

const Header = styled.p`
    font-size: 30px;
    text-transform: uppercase;

    @media screen and (max-width: 759px) {
        text-align: center;
    }
`

const Holder = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto auto;
    }
`

const Skill = styled.div`
    padding: 20px;
`

function Skills() {
    return (
        <Container>
               <Nav>
                    <NavLink to="/about" className="link"><Link>My Story</Link></NavLink>
                    <Link style={{'color': 'white' }}>♡♡♡</Link>
                    <NavLink to="/skills" className="link"><Link>Technical Skills</Link></NavLink>
                </Nav>
           <Description>
                <Header>Technical Skills</Header>
                <Holder>
                    <Skill> Ruby on Rails</Skill>
                    <Skill>Python</Skill>
                    <Skill><FontAwesomeIcon icon={faHtml5} /> HTML/CSS</Skill>
                    <Skill>SQL</Skill>
                    <Skill><FontAwesomeIcon icon={faJsSquare} /> Javascript</Skill>
                    <Skill><FontAwesomeIcon icon={faReact} /> React.js</Skill>
                    <Skill>Redux</Skill>
                    <Skill>Bootstrap</Skill>
                    <Skill>Git</Skill>
                    <Skill>RESTful APIs</Skill>
                </Holder>
           </Description>
        </Container>
    )
}

export default Skills;