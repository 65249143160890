import React from 'react';
import styled from 'styled-components';
import Avatar from 'avataaars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter, faDev } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
    display: flex;
    padding: 5%;
    justify-content: space-between;
    width: 72%;
    margin: auto;

    @media screen and (max-width: 759px) {
        display: grid; 
        justify-content: center;
    }
`

const Home = styled.div`
    text-align: left;
`

const Header = styled.p`
    font-size: 15px;
    // color: #9d7ea1;

    @media screen and (max-width: 759px) {
        text-align: center; 
    }
`

const Main = styled.h1`
    font-size: 65px;
    color: #9d7ea1;

    @media screen and (max-width: 759px) {
        font-size: 50px;
        text-align: center; 
    }
`

const Socials = styled.ul`
    padding: 20px 0px;
    list-style-type: none;
    list-style: none;

    @media screen and (max-width: 759px) {
        text-align: center; 
    }
`

const Link = styled.li`
    padding: 6px;
    display: inline;
    font-size: 20px;
    a {
        color: #9d7ea1;
    }
    a:hover {
        color: black;
    }
`

const Button = styled.button`
    border: 1px solid #9d7ea1;
    background-color: #9d7ea1;
    border-radius: 5px;
    padding: 6px 30px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    box-shadow: 5px 7px 10px #c4a4bb;
`


function Homepage() {
    return (
        <Container>
            <Home>
                <Header>HELLO</Header>
                <Main>I'm Megan</Main>
                <Header>Software Engineer based in the San Francisco Bay Area<br></br> specializing in Front End and  Full Stack Web Development</Header>
                <Socials>
                    <Link><a href="https://github.com/mwong068"><FontAwesomeIcon icon={faGithub} /></a></Link>
                    <Link><a href="https://www.linkedin.com/in/mwong068"><FontAwesomeIcon icon={faLinkedin} /></a></Link>
                    <Link><a href="https://twitter.com/mwong068"><FontAwesomeIcon icon={faTwitter} /></a></Link>
                    <Link><a href="https://dev.to/mwong068"><FontAwesomeIcon icon={faDev} /></a></Link>
                    <Link><a href="mailto: mwong068@gmail.com"><FontAwesomeIcon icon={faEnvelope} /></a></Link>
                </Socials>
            </Home>
                <Avatar
                    id='avatar'
                    // avatarStyle='Circle'
                    topType='LongHairStraight'
                    accessoriesType='Blank'
                    hairColor='Brown'
                    facialHairType='Blank'
                    clotheType='ShirtVNeck'
                    clotheColor='Black'
                    eyeType='Happy'
                    eyebrowType='Default'
                    mouthType='Smile'
                    skinColor='Light'
                />
        </Container>
    )
}

export default Homepage;