import React, { useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';


const Menu = styled.div`
    height: 100%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    max-width: 400px;
    z-index: 200;
    transform: 'translateX(-100%)';
    transition: transform 0.3s ease-out;
    padding: 15px;
    margin: 0;
`

const Item = styled.ul`
    // height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 20px;
    text-transform: uppercase;
    .link {
        text-decoration: none;
        color: black;
    }
    .link:hover {
        color: #9d7ea1;
        border: 1px solid #9d7ea1;
    }
`

const CloseMenu1 = styled.div`
    // position: absolute;
    margin: 0;
    margin-top: 15px;
    content: '';
    width: 20%;
    height: 4px; /* cross thickness */
    background-color: #dfcae9;
    transform: rotate(-45deg);
`

const CloseMenu2 = styled.div`
    // position: absolute;
    margin-top: -4px;
    content: '';
    width: 20%;
    height: 4px; /* cross thickness */
    background-color: #dfcae9;
    transform: rotate(45deg);
`

export default function SideMenu (props) {

    return (
        <div>
            {props.showNav === true ? 
            <Menu>
                <CloseMenu1 onClick={props.click} />
                <CloseMenu2 onClick={props.click} />
                <Item><NavLink to='/' className="link">Home</NavLink></Item>
                <Item><NavLink to='/about' className="link">About</NavLink></Item>
                <Item><NavLink to='/projects' className="link">Projects</NavLink></Item>
                <Item><NavLink to='/resume' className="link">Experience</NavLink></Item>
                <Item><NavLink to='/contact' className="link">Contact</NavLink></Item>
            </Menu>
            :
            null
            }
        </div>
    )
}