import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from './Navigation';

const Nav = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    height: 56px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
`

const NavToggle = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
`

const BurgerButton = styled.div`
    @media screen and (min-width: 759px) {
        display: none;  
    }
`

const Burger = styled.button`
    position: absolute;
    top: 25%;
    left: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    div {
        width: 2rem;
        height: 0.25rem;
        background: #dfcae9;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
    }
`
export default function BurgerMenu(props) {

    return (
        <Nav>
            <Menu />
            <NavToggle>
                <BurgerButton>
                    <Burger onClick={props.toggleNav}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </Burger>
                </BurgerButton>
            </NavToggle>
        </Nav>
    )
}

