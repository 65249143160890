import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Resume from '../assets/Megan W. Resume.pdf';

const Container = styled.div`
    padding: 10px 5%;
    width: 90%;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.1em;
`

const Links = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 759px) {
        display: none;  
        position: fixed;
    }
`

const Logo = styled.h2`
    // color: #dfcae9;
    .link {
        text-decoration: none;
        color: black;
        color: #9d7ea1;
        font-size: 28px;
        font-weight: 500;
    }

    @media screen and (max-width: 759px) {
        margin: auto;
        margin-top: 1%;
    }
`

const Item = styled.li`
    display: inline;
    padding: 20px;
    text-transform: uppercase;
    .link {
        text-decoration: none;
        color: black;
    }
    .link:hover {
        // color: #9d7ea1;
        border-bottom: 1px solid #9d7ea1;
        width: 80%;
    }
`

const Button = styled.button`
    border: 1px solid #9d7ea1;
    // background-color: #9d7ea1;
    background-color: white;
    // border-radius: 5px;
    padding: 8px 35px;
    color: #9d7ea1;
    letter-spacing: 0.1em;
    font-size: 11px;
    // box-shadow: 5px 7px 10px #c4a4bb;
    text-transform: uppercase;
    :hover {
        border: 1px solid #9d7ea1;
        background-color: #9d7ea1;
        padding: 8px 35px;
        color: black;
        letter-spacing: 0.1em;
        font-size: 11px;
        text-transform: uppercase;
        color: white;
    }

    @media screen and (max-width: 759px) {
        display: none;  
        position: fixed;
    }
`

export default function Menu() {

    return (
        <div>
        <Container>
            <Logo><NavLink to='/' className="link">MW</NavLink></Logo>
            <Links>
                <Item><NavLink to='/' className="link">Home</NavLink></Item>
                <Item><NavLink to='/about' className="link">About</NavLink></Item>
                <Item><NavLink to='/projects' className="link">Projects</NavLink></Item>
                <Item><NavLink to='/resume' className="link">Experience</NavLink></Item>
                <Item><NavLink to='/contact' className="link">Contact</NavLink></Item>
            </Links>
            <a href={Resume} target="_blank"><Button>Resume</Button></a>
        </Container>
        </div>
    )
}