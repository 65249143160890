import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gif from '../../assets/pick-and-chews.gif';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 75%;
    margin: auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
        margin: auto;
        width: 100%;
    }

    .buttons-container {
        @media screen and (max-width: 759px) {
            display: grid;
        }
    }
`

const Picture = styled.div`
    .profile {
        width: 100%;
        border: 1px solid black;
    }
`

const Header = styled.p`
    font-size: 40px;
    // text-transform: uppercase;

    @media screen and (max-width: 759px) {
        margin: auto;
        text-align: center;
        padding-bottom: 30px;
    }
`

const Description = styled.div`
    text-align: center;
    width: 70%;

    @media screen and (max-width: 759px) {
        margin: auto;
    }
`

const Button = styled.button`
    border: 1px solid #9d7ea1;
    background-color: white;
    padding: 8px 35px;
    color: #9d7ea1;
    letter-spacing: 0.1em;
    font-size: 11px;
    text-transform: uppercase;
    :hover {
        border: 1px solid #9d7ea1;
        background-color: #9d7ea1;
        padding: 8px 35px;
        color: black;
        letter-spacing: 0.1em;
        font-size: 11px;
        text-transform: uppercase;
        color: white;
    }
`

const Title = styled.p`
    text-transform: uppercase;
`

const Skills = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    @media screen and (max-width: 759px) {
        grid-template-columns: auto auto;
    }
`

const Spacer = styled.span`
    padding: 20px;

    @media screen and (max-width: 759px) {
        padding: 5px;
    }
`



function Pick() {

    return (
        <Container>
           <Description>
               <div>
                    <p>PROJECTS</p>
                    <Header>Pick and Chews: A Restaurant Locator App</Header>
               </div>
               <div className="buttons-container">
                    <a href="https://github.com/jolouie7/Pick-and-Chews-app-frontend"><Button>Front End</Button></a>
                    <Spacer />
                    <a href="https://github.com/mwong068/Pick-And-Chews-app-backend"><Button>Back End</Button></a>
               </div>
               <div>
                   <Spacer />
                    <p>A helpful food choosing application, made with indecisive and picky foodies in mind. Built using purely vanilla Javascript, with a Rails backend database and data fetched from the Yelp API. It has full CRUD capability.</p>
               </div>
               <div>
                   <Spacer />
                    <Title>Built using</Title>
                    <Skills>
                        <p>Ruby on Rails</p>
                        <p>PostgreSQL</p>
                        <p>Javascript</p>
                        <p>Yelp API</p>
                        <p>Bootstrap</p>
                    </Skills>
                </div>
           </Description>
            <Picture>
                <br></br>
                <img src={gif} className="profile" />
            </Picture>
        </Container>
    )
}

export default Pick;