import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gif from '../../assets/hikr.gif';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 75%;
    margin: auto;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
        margin: auto;
        width: 100%;
    }
`

const Picture = styled.div`
    .profile {
        width: 100%;
        border: 1px solid black;
    }
`

const Header = styled.p`
    font-size: 40px;
    // text-transform: uppercase;

    @media screen and (max-width: 759px) {
        margin: auto;
        text-align: center;
        padding-bottom: 30px;
    }
`

const Description = styled.div`
    text-align: center;
    width: 70%;
    display: grid;

    @media screen and (max-width: 759px) {
        margin: auto;
    }
`
const Button = styled.button`
    border: 1px solid #9d7ea1;
    // background-color: #9d7ea1;
    background-color: white;
    // border-radius: 5px;
    padding: 8px 35px;
    color: #9d7ea1;
    letter-spacing: 0.1em;
    font-size: 11px;
    // box-shadow: 5px 7px 10px #c4a4bb;
    text-transform: uppercase;
    :hover {
        border: 1px solid #9d7ea1;
        background-color: #9d7ea1;
        padding: 8px 35px;
        color: black;
        letter-spacing: 0.1em;
        font-size: 11px;
        text-transform: uppercase;
        color: white;
    }
`

const Spacer = styled.span`
    padding: 10px;

    @media screen and (max-width: 759px) {
        padding: 5px;
    }
`

const Title = styled.p`
    text-transform: uppercase;
`

const Skills = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    @media screen and (max-width: 759px) {
        grid-template-columns: auto auto;
    }
`

const Buttons = styled.div`
    // padding: 20px;

    @media screen and (max-width: 759px) {
        display: grid;
    }
`

function Hikr() {

    return (
        <Container>
           <Description>
               <div>
                    <Title>projects</Title>
                    <Header>Hikr: A Hiking Locator App</Header>
                    {/* <p>A Hiking Locator App</p> */}
               </div>
                <Buttons>
                    <a href="https://hikr.vercel.app"><Button>Live Demo</Button></a>
                    <Spacer />
                    <a href="https://github.com/willdiep/hikr-front-end"><Button>Front End</Button></a>
                    <Spacer />
                    <a href="https://github.com/willdiep/hikr-back-end"><Button>Back End</Button></a>
                </Buttons>
                <div>
                    <Spacer />
                    <p>A useful hiking trail locator app for trails all over the world that allows users to favorite and tag trails based on amenities. Utilizing three different APIs.</p>
                </div>
                <div>
                    <Title>Built using</Title>
                    <Skills>
                        <p>Ruby on Rails</p>
                        <p>React</p>
                        <p>PostgreSQL</p>
                        <p>LocationIQ API</p>
                        <p>Mapbox API</p>
                        <p>Hiking Project API</p>
                    </Skills>
                </div>
           </Description>
            <Picture>
                <br></br>
                <img src={gif} className="profile" />
            </Picture>
        </Container>
    )
}

export default Hikr;