import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from 'avataaars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter, faDev } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
    width: 70%;
    margin: auto;
    
    .button {
        border: 1px solid #9d7ea1;
        background-color: #9d7ea1;
        padding: 8px 35px;
        color: black;
        letter-spacing: 0.1em;
        font-size: 11px;
        text-transform: uppercase;
        color: white;
        :hover {
            border: 1px solid #9d7ea1;
            background-color: white;
            padding: 8px 35px;
            color: #9d7ea1;
            letter-spacing: 0.1em;
            font-size: 11px;
            text-transform: uppercase;
        }
    }

    .input-field {
        padding: 10px;
        font-family: 'Karla', sans-serif;
        font-size: 14px;
    }

    @media screen and (max-width: 759px) {
        width: 100%;
        margin: auto;
        padding: 0;
    }
`
const Header = styled.p`
    font-size: 30px;
    text-align: left;
    text-transform: uppercase;

    @media screen and (max-width: 759px) {
        text-align: center;
    }
`

const Holder = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 759px) {
        display: grid;
        grid-template-columns: auto;
        margin: auto;
        width: 100%;
    }
`

const Image = styled.div`
    padding: 20px;
    .photo {
        width: 70%;
        opacity: 0.6;
    }

    @media screen and (max-width: 759px) {
        width: 100%;
        margin: auto;
        padding: 0;
    }
`

const Text = styled.div`
    padding: 20px;
    text-align: center;
`

const Socials = styled.ul`
    list-style-type: none;
    list-style: none;
    margin: auto;
    padding: 0px;
`

const Link = styled.li`
    padding: 6px;
    display: inline;
    font-size: 20px;
    a {
        color: #9d7ea1;
    }
    a:hover {
        color: black;
    }
`

const Title = styled.p`
    text-transform: uppercase;
    font-size: 15px;
`

const Spacer = styled.div`
    padding: 10px;
`

function Contact() {
    const [name, setName] = useState("Name");
    const [email, setEmail] = useState("Email");
    const [message, setMessage] = useState("Your Message");

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(JSON.stringify({name, email, message}))

        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://hlbd3uuj28.execute-api.us-east-1.amazonaws.com/dev/static-site-mailer', true);
        xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    
        // Send the collected data as JSON
        xhr.send(JSON.stringify({name, email, message}));
    
        // Callback function
        xhr.onloadend = response => {
          if (response.target.status === 200) {
            // The form submission was successful
            event.target.reset();
            event.target.innerHTML = 'Thanks for the message. I’ll be in touch shortly.';
          } else {
            // The form submission failed
            event.target.innerHTML = 'Something went wrong. Please try again.';
            console.error(JSON.parse(response.target.response).message);
          }
        };
    };

    return (
        <Container>
            <Header>Contact</Header>
            <Holder>
                <Image>
                    {/* <img src="https://images.unsplash.com/photo-1527141774629-9f3a1c0758f2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80" className="photo" alt="golden gate bridge" /> */}
                    <Avatar
                    avatarStyle='Circle'
                    topType='LongHairStraight'
                    accessoriesType='Blank'
                    hairColor='Brown'
                    facialHairType='Blank'
                    clotheType='ShirtVNeck'
                    clotheColor='White'
                    eyeType='Happy'
                    eyebrowType='Default'
                    mouthType='Smile'
                    skinColor='Light'
                    />
                </Image>
                <Text>
                    <p style={{'fontSize': '20px' }}>Have a question or would like to get in touch?</p>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <input type="text" name="name" value={name} className="input-field" onChange={e => setName(e.target.value)} />
                        </label>
                        <Spacer />
                        <label>
                            <input type="text" name="email" value={email} className="input-field" onChange={e => setEmail(e.target.value)} />
                        </label>
                        <Spacer />
                        <label>
                            <input type="text" name="message" value={message} className="input-field" onChange={e => setMessage(e.target.value)} />
                        </label>
                        <Spacer />
                        <input type="submit" value="Submit" className="button" />
                    </form>
                    <Spacer /><p style={{'fontSize': '8px' }}>♡</p>
                    <Title>socials</Title>
                    <Socials>
                        <Link><a href="https://github.com/mwong068"><FontAwesomeIcon icon={faGithub} /></a></Link>
                        <Link><a href="https://www.linkedin.com/in/mwong068"><FontAwesomeIcon icon={faLinkedin} /></a></Link>
                        <Link><a href="https://twitter.com/mwong068"><FontAwesomeIcon icon={faTwitter} /></a></Link>
                        <Link><a href="https://dev.to/mwong068"><FontAwesomeIcon icon={faDev} /></a></Link>
                        <Link><a href="mailto: mwong068@gmail.com"><FontAwesomeIcon icon={faEnvelope} /></a></Link>
                    </Socials>
                </Text>
            </Holder>
        </Container>
    )
}

export default Contact;